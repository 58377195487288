import util from './util';
import { IS_DEBUG  } from '@/common/debug';
import axios from 'axios';
import { getStore } from '../store';
import signLog from '@/components/sign-log';
import popMgr from '@/components/global/popMgr';
import questionnaire from '@/components/questionnaire';
import {
    emitTrack,
    regType,
    state,
    resetRegisStatus,
    setRegisStatus,
    registrationEnum
} from '@/components/sign-log/register-track';
import { getRegistrationOptions } from './autopopup';
// const HePageKey = [
//     'evaluation',
//     'evaluation-seller',
//     'seller-evaluation',
//     'he-home'
// ];
async function handleCredentialResponse(data, globalData) {
    resetRegisStatus();
    setRegisStatus({
        registrationType: registrationEnum.registrationType.chromeApi
    });
    emitTrack(regType.google, state.success);
    const clientId = data.clientId;
    const token = data.credential;
    try {
        let role = globalData.registerUserType;
        let autopopup=globalData.registration && (globalData.registration.autopopup || {});
        let [,registration]=getRegistrationOptions(globalData.isLandingPage,autopopup);
        if(Object.keys(registration).length){
            role = registration.registerUserType;
        }
        const ret = await getUserInfo({
            clientId,
            token,
            ignoreBuyerPhone:
                globalData.registration &&
                globalData.registration.ignoreBuyerPhone
                    ? 1
                    : 0,
            role: role
        });
        const { nextStep, registerInfo, user } = ret.data || {};
        if (nextStep === 'third-party-registry') {
            if (window.siteAppVm) {
                signLog.register(
                    {
                        authData: {
                            uid: registerInfo.uid,
                            from: registerInfo.from,
                            email: registerInfo.email,
                            token: registerInfo.token,
                            fullname: registerInfo.fullName || '',
                            firstName: registerInfo.firstName || '',
                            lastName: registerInfo.lastName || '',
                            role: registerInfo.role
                        },
                        showThirdNext: true,
                        isAutoRegister: false,
                        role: registerInfo.role
                    },
                    '',
                    true,
                    true,
                    true
                );
            }
        } else if (
            nextStep === 'third-party-login-completed' ||
            nextStep === 'third-party-registry-completed'
        ) {
            getStore('user').login({ user });
            if (nextStep === 'third-party-registry-completed') {
                emitTrack(regType.register, state.success);
                let inquiry = !!(
                    globalData.questionNaire && globalData.questionNaire.inquiry
                );
                inquiry &&
                    questionnaire.open({
                        globalData: globalData
                    });
            }
        }
    } catch (e) {
        console.error(e);
    }
}

if (IS_DEBUG) {
    window.handleCredentialResponse = handleCredentialResponse;
}


const getUserInfo = async params =>
    axios.post(`/api-site/third-party/login/googleAutoRegister`, params);

export function initOneStepLogin(globalData) {
    setTimeout(() => {
        const userStore = getStore('user');
        const googleAppId = globalData.googleAppId;
        const loginStatus = userStore.loginStatus;
        if (loginStatus === 1) return;
        const cur = popMgr.getCurrent();
        if (cur && cur.name === 'sign-log') return;
        if (globalData.registration?.allowOneStepLogin === false) return; //  Can't judge undefined, Because the storage station is undefined， But need the function
        // HE stand
        if (
            globalData.is_evaluation_site &&
            globalData.is_evaluation_site === '1'
            // (globalData.pageKey && HePageKey.includes(globalData.pageKey))
        )
            return;

        if (
            window.sitePrepareData &&
            window.sitePrepareData()._disableChromeAuthority
        ) {
            return;
        }
        util.addScript('https://accounts.google.com/gsi/client', true).then(
            () => {
                let google = window.google;
                google.accounts.id.initialize({
                    client_id: googleAppId,
                    cancel_on_tap_outside: false,
                    use_fedcm_for_prompt: false,
                    callback: data =>
                        handleCredentialResponse({...data,clientId:googleAppId}, globalData)
                });
                const curPop = popMgr.getCurrent();
                if (curPop && curPop.name === 'sign-log') return;
                google.accounts.id.prompt();
            }
        );
    }, 5000);
}
