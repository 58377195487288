import util from "../util"
import {registerSyncFun , COOKIE_LEVEL} from '@/components/cookieAuthority/cookieStatus.js';
export default async(globalData)=> {
    if(globalData?.track?.adwerxUrl && !util.isSSRServer){
        registerSyncFun(()=>{
            if([window.location.pathname, globalData.pageKey].includes(globalData.track.pageUrlForAdwerx) || !globalData.track.pageUrlForAdwerx){
                util.addScript(globalData.track.adwerxUrl)
            }
        },{
            util,
            level: COOKIE_LEVEL.targeting
        });
       
    }
}