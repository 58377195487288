import { defineAsyncComponent } from 'vue';
import siteImg from './site-img.js';
import siteVideo from './videos/site-video';
import imgBox from './img-box.vue';
import pop from './pop.vue';
import moduleBox from './module-box.vue';

import ImSelect from './select/select';
import ImOption from './select/option';
import ImOptionGroup from './select/option-group';
import ImInput from './input/input.vue';
import ImTag from './tag/tag.vue';
import ImPicker from './m-picker/picker.vue';
import ImCheckbox from './checkbox.vue';
import ImCalendar from './m-calendar/index.vue';

import Popper from './popper/popper.vue';
import SchemaInfo from './schemaInfo.vue';
import FederatedJs from '@/modules/md-custom-code/federated/index.vue';
//import teleport from './teleport.vue';
import ModuleLayout from "./module-layout.vue";
import mdLazy from '../md-lazy.vue';
import Title from './text/title.vue';
import SubTitle from './text/sub-title.vue';
import Paragraph from './text/paragraph.vue';
import Button from './button/index.vue';
import OnlyClient from './only-client.vue'
import H1 from './text/h1.vue'

import lyColumn from '../../layout/column';

export default function (app) {
    app.component('site-img', siteImg);
    app.component('site-video', siteVideo);
    app.component('img-box', imgBox);
    app.component('pop', pop);
    app.component('module-box', moduleBox);
    // app.component('Federated', Federated);
    app.component('federated-js', FederatedJs);
    // app.component(switchDevice.name, switchDevice);
    app.component(ImSelect.name, ImSelect);
    app.component(ImOption.name, ImOption);
    app.component(ImOptionGroup.name, ImOptionGroup);
    app.component(ImInput.name, ImInput);
    app.component(ImTag.name, ImTag);
    app.component(ImPicker.name, ImPicker);
    app.component(ImCheckbox.name, ImCheckbox);
    app.component(ImCalendar.name, ImCalendar);
    app.component(Popper.name, Popper);
    app.component('schema-info', SchemaInfo);
    // app.component('teleport', teleport);
    app.component('md-lazy', mdLazy);
    app.component('module-layout', ModuleLayout);

    app.component('site-title', Title);
    app.component('site-sub-title', SubTitle);
    app.component('site-p', Paragraph);
    app.component('site-button', Button);
    app.component('site-h1', H1);

    app.component('ly-column', lyColumn);
    app.component('only-client', OnlyClient);
}



