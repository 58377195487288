import { createApp, defineAsyncComponent } from 'vue';
import plugin from '@/plugins';
import util from '@/common/util';
// import advRegister from '@/components/sign-log/advertising-register/register.js';
import { getStore } from '../../store';
import { COOKIE_AUTHORITY_KEY } from './theme/mixin.js';
import {getCookieSetting,COOKIE_LEVEL} from "./cookieStatus.js";
const themeMap = {
    standard: defineAsyncComponent(() =>
        import(
            /* webpackChunkName: "common/cookie-authority/standard" */ './theme/standard.vue'
        )
    ),
    ca : defineAsyncComponent(() => import(
        /* webpackChunkName: "common/cookie-authority/ca" */ './theme/ca.vue'
    ))
};

const getThemeMD = ()=>{
    const setting = getCookieSetting(util);
    if(setting[COOKIE_LEVEL.ca] === true){
        return themeMap.ca;
    }
    return themeMap.standard
}

/**
 *  condition : 1.  not logged in  2.  not asked  3.  No AD
 */
export default function () {

    return new Promise((resolve)=>{
        if (util.isScreenshot){
            resolve()
            return
        }
        const pageStore = getStore('page');
        const isCmsListing = window.location.href.includes('/listing-for-cms');
        //  Exclude Ad Pages   and  cms
        const isAd = /x_ads_source/.test(location.search);
        const globalData = pageStore.page;
        if (util.inCms || isCmsListing) {
            resolve()
            return
        }
        // if (
        //     globalData._disableCookieAuthority ||
        //     (advRegister.isShow(globalData) && globalData.isLandingPage)
        // ) {
        //     resolve()
        //     return
        // }
        if (localStorage.getItem(COOKIE_AUTHORITY_KEY) != '1') {

            let cookieAuthority = createApp(
                getThemeMD(),
                {
                    onDestroy: () => {
                        if (cookieAuthority) {
                            cookieAuthority.unmount();
                            cookieAuthority = null;
                        }
                        resolve()
                    }
                }
            );
            plugin.baseInstall(cookieAuthority, true);
            cookieAuthority.mountSelf();
        }else{
            resolve()
        }
    })

}
