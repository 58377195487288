<template>
    <div class="video-box fb" :class="{ mobile: isMobile }">
        <div id="fb-root"></div>
        <div class="video-wrap" ref="video" v-html="embedVideo"></div>
    </div>
</template>

<script>
import util from '@/common/util';

export default {
    props: {
        src: {
            type: String,
            default: ''
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        muted: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: false
        },
        playsinline: {
            type: Boolean,
            default: false
        },
        preview: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            embedVideo: '',
            appId: '5915489508564870',
            videoRatio: 9 / 16,
            facebookId: util.uuidv4()
        };
    },
    computed: {
        isMobile(){
            return this.platform.isMobile;
        }
    },
    mounted() {
        this.videoInit();
    },
    watch: {
        src() {
            this.videoInit();
        }
    },
    methods: {
        videoInit() {
            
            let _this = this;
            _this.videoPlayer = null;
            _this.$emit('setFacebook');

            let width=this.$el.parentElement.offsetWidth  || window.innerWidth ;
            let height=this.$el.parentElement.offsetHeight  || window.innerHeight;
            let rect = this.calcRect({
                width, height,
                playOnPop: this.$el.parentElement.offsetWidth == 0
            });
            width = rect.width;
            height = rect.height;

            console.log('fb video init',width,height);
            _this.embedVideo = `
                <div class="fb-video"
                    id="fb-${_this.facebookId}"
                    ref="nofollow"
                    data-href="${_this.src}"
                    data-allowfullscreen="true"
                    data-show-captions="true"
                    data-autoplay="${_this.autoplay}"
                    data-width=${Math.ceil(width)}
                    data-height=${Math.ceil(height)}
                ></div>
            `;

            util.addScript('https://connect.facebook.net/en_US/sdk.js').then(
                () => {
                    FB.init({
                        appId: _this.appId,
                        xfbml: true,
                        version: 'v16.0'
                    });
                    FB.Event.subscribe('xfbml.ready', function (msg) {
                        if (msg.type === 'video') {
                            _this.videoPlayer = msg.instance;
                            _this.$emit('loadedmetadata',_this);
                            _this.$emit('canplay');
                            _this.$emit('loadPreview');

                            if (_this.autoplay && _this.videoPlayer.play) {
                                _this.$emit('play');
                                _this.toggleMuted(true);
                            }

                            _this.bindEvent();
                        }
                    });
                }
            );
        },
        calcRect(option){
            let {width,height,playOnPop} =  option;
            let ratio = height/width;
            if(this.platform.isMobile){
                if(playOnPop){
                    height = height - 200;
                }
                return {
                    width,
                    height
                }
            }

            // pc
            width = width -260;
            height = width * ratio ;
            return {
                width,
                height
            }
        },
        calcVideoStyle(option) {
            let { height,ratio,playOnPop  } = option;
            let iframeDom=this.$el;
            // ratio = h / w;
            // w = h/ratio;
            let rect = this.calcRect({
                width: height/ratio,
                height,
                playOnPop: playOnPop
            });
            height = rect.height;
            let width = rect.width;
            if(this.platform.isMobile){
                const el = iframeDom.parentElement;
                iframeDom=this.$refs.video.querySelector('iframe');
                iframeDom.style.width=width+'px';
                iframeDom.style.height=height+'px';

            }else{
                iframeDom.style.width= width +'px';
                iframeDom.style.height= height +'px';
            }
            return ratio;
        },
        bindEvent() {
            let _this = this;

            _this.videoPlayer.subscribe('finishedPlaying', function () {
                console.log('fb ended ===>>>');
                _this.$emit('ended');
            });

            _this.videoPlayer.subscribe('error', function (err) {
                console.log('fb error ===>>>');
                _this.$emit('error', err);
            });
        },
        playVideo() {
            this.videoPlayer.play();
        },
        pauseVideo() {
            console.log('pauseVideo ===>>')
            this.videoPlayer.pause();
        },
        toggleMuted(muted) {
            if (muted) {
                this.videoPlayer.mute();
            } else {
                this.videoPlayer.unmute();
            }
        }
    }
};
</script>

<style lang="scss">
.video-box.fb {
    position: relative;
    width: 100%;
    height: 100%;
    &.mobile {
        z-index: 2;
    }
    .video-wrap {
        height: 100%;
        width: 100%;
         .fb-video {
            width: 100% !important;
            height: 100% !important;
            text-align: center;
            span {
                width: 100% !important;
                height: 100% !important;
                display:flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .video-action {
        position: absolute;
        .iconfont {
            position: absolute;
            width: 36px;
            height: 36px;
            left: 20px;
            bottom: 20px;
            font-size: 16px;
            color: #fff;
            border-radius: 4px;
            z-index: 9;
            cursor: pointer;
            text-align: center;
            vertical-align: middle;
            line-height: 36px;
            &::after {
                background: #282828;
                opacity: 0.3;
                border-radius: 4px;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: '';
                z-index: -1;
            }
        }
    }
}
</style>
