import util from '../util';
import {registerSyncFun , COOKIE_LEVEL} from '@/components/cookieAuthority/cookieStatus.js';
export default async function () {
    if (util.inCmsPageEditor || !util.isReallyUser) {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }

    try {
        var globalData = window.sitePrepareData();
        var trackData = globalData.track || {};
        var gadConversionId = trackData.gadConversionId;
        var gadAWId = '';
        if (gadConversionId) {
            gadAWId = gadConversionId.split('/')[0];
        }
        if (!gadAWId || gadAWId.indexOf('AW-') === -1) {
            return;
        }
        
        registerSyncFun(()=>{
            // gadConversionId  Extracted from inside 
            var jsUrl = 'https://www.googletagmanager.com/gtag/js?id=' + gadAWId;
            util.addScript(jsUrl, true);

            gtag('js', new Date());
            gtag('config', gadAWId);
            window.siteAppVm.$EventBus.$on('siteTrack', function (data) {
                if (data.trackType === 'register') {
                    gtag('event', 'conversion', {
                        send_to: gadConversionId
                    });
                }
            });
        },{
            util,
            level: COOKIE_LEVEL.targeting
        })
        
    } catch (e) {
        console.error('ga-conversion error', e);
    }
}
