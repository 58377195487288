import util from '@/common/util';
import dirty from '@/dirty';
import { getRootVar } from './init/data/getDataFromJsp';
import { blendRgba } from '@/common/utils/handle-color.js';

const extractRootVar = function (style, key) {
    let reg = new RegExp(`${key}:\\s*([^;}]*)`, 'm');
    let matches = style.match(reg);
    return matches ? matches[1] : '';
};

function calcHoverColor(globalData, style) {
    let { templateId } = globalData;
    if (templateId < 14) return;
    let bgColor = getRootVar('--g-btn-background');
    if (style) {
        bgColor = extractRootVar(style, '--g-btn-background');
    }
    let hoverColor = blendRgba('rgba(25,25,25,0.1)', bgColor);
    document.documentElement.style.setProperty('--color-theme', bgColor);
    document.documentElement.style.setProperty('--color-hover', hoverColor);
}

export default function ({
    globalData,
    platform: { platform },
    siteThemeStyle
}) {
    try {
        dirty({ globalData });

        //  Remove the parent window of the details page pop-up window loading
        if (util.inPopDetailPage) {
            window.parent.__curDetailPop.setLoading(false);
        }
    } catch (e) {}

    if (window.siteAppVm) {
        window.siteAppVm.$EventBus.$emit('siteTrack', {
            trackType: 'page-change'
        });
    }
    if (util.inCms) {
        document.body.classList.add(globalData.template);
    }
    if (platform.isMobile) {
        document.body.classList.add('mobile');
    }
    if (globalData.pageKey) {
        document.body.classList.add(globalData.pageKey);
    }
    if (globalData.isLandingPage) {
        document.body.classList.add('landing-page');
    }
    if (globalData.isTestSite) {
        document.body.classList.add('test-site');
    }
    if (globalData.siteStatus === 0) {
        document.body.classList.add('pending');
    }

    if (util.isReallyUser) {
        let requestId = document.querySelector('#requestId');
        if (requestId) {
            // crm traffic report  Statistical data
            new Image().src = '/0.gif?u=' + (requestId.value || '');
        }
    }

    calcHoverColor(globalData, siteThemeStyle);
}
