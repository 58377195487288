import util from '../util';
import {registerSyncFun , COOKIE_LEVEL} from '@/components/cookieAuthority/cookieStatus.js';

export default async (globalData)=> {
    if(globalData?.track?.fubCode && globalData?.track?.fubId){
        if (!util.isSSRServer)  {
            registerSyncFun(()=>{
                const fubCode = globalData.track.fubCode
                const matches = fubCode.replace(`\x3C!-- begin Widget Tracker Code -->\n\n`, '').replace('\n\n\x3C!-- end Widget Tracker Code -->', '').replace(/[\n\r]+/g, '');
                const scriptNode = document.createElement('script')
                scriptNode.textContent = matches
                document.body.appendChild(scriptNode)
            },{
                util,
                level: COOKIE_LEVEL.targeting
            });
        }
    }
}