/**
 * 当需要使用时获取jsp上的一些原始值, 包括(creatorId)
 */
export function getOtherData() {
    return {
        get creatorId() {
            let creatorIdEl = document.getElementById('creatorId');
            return parseInt(creatorIdEl && creatorIdEl.value);
        },
        get evaluationSite() {
            let evaluationSite = document.getElementById('evaluationSite');
            return parseInt(evaluationSite && evaluationSite.value);
        },
        get delegateUserId() {
            let evaluationSite = document.getElementById('delegateUserId');
            return parseInt((evaluationSite && evaluationSite.value) || '0');
        }
    };
}

const rootVarHandleMap = {
    '--mg-title-size': v => (v ? parseInt(v) : 24),
    '--mg-sub-title-size': v => (v ? parseInt(v) : 16)
};

export function getRootVar(key) {
    let ret = '';
    try {
        ret = getComputedStyle(document.documentElement).getPropertyValue(key).trim();
        let fn = rootVarHandleMap[key];
        if (fn) {
            ret = fn(ret);
        }
    } catch (e) {
        console.warn(e);
    }
    return ret;
}

/**
 * 获取全局数据(jsp上的原始值)
 */
export function getGlobalData() {
    return window.GLOBAL_DATA;
}

/**
 * 获取页面数据(jsp上的原始值)
 */
export function getPageData() {
    let data = window.sitePageJSON;
    // if (!data) {
    //     try {
    //         data = JSON.parse(
    //             document.getElementById('page-data').value.replace(/\n/g, '')
    //         );
    //     } catch (error) {
    //         console.error('resolve page data error.');
    //     }
    // }
    return data;
}
